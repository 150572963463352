import * as React from 'react';
import * as _ from 'lodash';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { ProductNodeModel } from './ProductNodeModel';
import { DefaultPortLabel, DefaultPortModel } from '@projectstorm/react-diagrams-defaults';
import styled from '@emotion/styled';
import styles from './ProductNodeWidget.module.scss';


namespace S {
	export const Node = styled.div<{ background: string; selected: boolean }>`
		background-color: ${(p) => (p.selected ? '#acca5d!important' : 'white!important')};
		border-radius: 5px;
		font-family: sans-serif;
		color: white;
		border: solid 2px black;
		overflow: visible;
		font-size: 11px;
		border: solid 2px ${(p) => (p.selected ? '#acca5d!important' : 'white')};
	`;
	


	export const OverlayDetail = styled.div`
	`;
	export const Overlay = styled.div<{selected:boolean}>`
		display:  ${(p) => (p.selected ? 'block' : 'none')};
		background: rgba(255,255,255,0.8);
		color: black;
		position: absolute;
		width: 96%;
		border: 1px solid white;
		margin-top: 10px;
		padding: 5px;
		box-shadow: 0px 0px 6px rgba(0,0,0,0.5);

	`;
	export const Title = styled.div`
		background: rgba(0, 0, 0, 0.3);
		display: flex;
		white-space: nowrap;
		justify-items: center;
	`;

	export const TitleName = styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`;

	export const Ports = styled.div`
		display: flex;
		background: none;
	`;

	export const PortsContainer = styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&:first-of-type {
			margin-right: 78%;
		}

		&:only-child {
			margin-right: 0px;
		}
	`;
}

export interface DefaultNodeProps {
	node: ProductNodeModel;
	engine: DiagramEngine;
}

/**
 * Default node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export class ProductNodeWidget extends React.Component<DefaultNodeProps> {
	generatePort = (port:DefaultPortModel) => {
		return <DefaultPortLabel engine={this.props.engine} port={port} key={port.getID()} />;
	};
	
	render() {

		return (
			<S.Node className={styles.nodeWrapper}
				data-default-node-name={this.props.node.getOptions().name}
				selected={this.props.node.isSelected()}
				background="rgb(0,192,255)">
				<S.Title className={styles.nodeTitle}>
					<S.TitleName>{this.props.node.getOptions().name}</S.TitleName>
				</S.Title>
				<S.Ports className={styles.portWrapper}>
					<S.PortsContainer className={styles.portOut}>{_.map(this.props.node.getOutPorts(), this.generatePort)}</S.PortsContainer>
					<S.PortsContainer className={styles.portIn}>{_.map(this.props.node.getInPorts(), this.generatePort)}</S.PortsContainer>

				</S.Ports>
				<S.Overlay selected={this.props.node.isSelected()}>
					<S.OverlayDetail>Total Lead Time: { Math.round((Number(this.props.node.getOptions().lead_days) + Number.EPSILON) * 100) / 100}</S.OverlayDetail>
					<S.OverlayDetail>Total Cost: {Math.round((this.props.node.getOptions().cost + Number.EPSILON) * 100) / 100}</S.OverlayDetail>
				</S.Overlay>
			</S.Node>
		);
	}
}
