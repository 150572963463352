import React, { useEffect, useState } from 'react';
import styles from './NodeMap.module.scss';

import { DiagramModel } from '@projectstorm/react-diagrams';
import {
	CanvasWidget
} from '@projectstorm/react-canvas-core';

import { DiagramState } from '../../react-diagrams/states/DiagramState';
import { ProductNodeFactory } from '../../react-diagrams/entities/nodes/product/ProductNodeFactory';
import { CustomerNodeFactory } from '../../react-diagrams/entities/nodes/customer/CustomerNodeFactory';
import { ChooseNodeFactory } from '../../react-diagrams/entities/nodes/choose/ChooseNodeFactory';
import { SupplierNodeFactory } from '../../react-diagrams/entities/nodes/supplier/SupplierNodeFactory';
import { ComponentNodeFactory } from '../../react-diagrams/entities/nodes/component/ComponentNodeFactory';
import { ShippingNodeFactory } from '../../react-diagrams/entities/nodes/shipping/ShippingNodeFactory';
import { MyLinkFactory } from '../../react-diagrams/entities/links/MyLinkFactory';
import { MyPortFactory } from '../../react-diagrams/entities/ports/MyPortFactory';
import { useAuth } from '../../services/AuthContext';
import { NodeViewSidebar, ServerParentList} from '../../fragments/NodeViewSidebar/NodeViewSidebar';
import { useAppContext } from '../../services/AppContext';
//import { MyLabelFactory } from '../../react-diagrams/entities/labels/MyLabelFactory';


interface NodeMapProps {
}
export function NodeMap(props: NodeMapProps) {
	const auth = useAuth();
	const app = useAppContext();
	const [parentList, setParentList] = useState<ServerParentList>({customers: [], products: []});

	useEffect(() => {
		app.diagramEngine.getNodeFactories().registerFactory(new CustomerNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new ProductNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new ChooseNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new SupplierNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new ShippingNodeFactory());
		app.diagramEngine.getNodeFactories().registerFactory(new ComponentNodeFactory());
		app.diagramEngine.getLinkFactories().registerFactory(new MyLinkFactory());
		//app.diagramEngine.getLabelFactories().registerFactory(new MyLabelFactory());
		app.diagramEngine.getPortFactories().registerFactory(new MyPortFactory());
		app.diagramEngine.getStateMachine().pushState(new DiagramState());

		getDiagram('');
	}, []);

	const getDiagram = (id: string) => {
		auth.getNodeDiagram(id)
			.then((result:any) => {
				var model2 = new DiagramModel();
				model2.deserializeModel(result.data.model, app.diagramEngine);

				app.diagramEngine.setModel(model2);

				setParentList(result.data.parents);

				app.setCurrParentID(result.data.currParentID);
			});
	}
	const getCompareDiagram = (id: string) => {
		auth.getNodeDiagram(id)
			.then((result:any) => {

				// get existing product diagram
				auth.getNodeDiagram(app.currParentID)
				.then((existingResult:any) => {
					
					var compareModel = result.data.model;
				
					compareModel.layers.push(existingResult.data.model.layers[0],existingResult.data.model.layers[1]);
					var existingModel = new DiagramModel();
					existingModel.deserializeModel(compareModel, app.diagramEngine);
					console.log(compareModel);
					app.diagramEngine.setModel(existingModel);

					
				});

				

			//	app.setCurrParentID(result.data.currParentID);
			});
	}

	const adjustPosition = () => {
		console.log("fired adjust position");

		var model = app.diagramEngine.getModel();

		const nodes = model.getNodes();
		//let node = nodes[Object.keys(nodes)[0]];

		auth.getNodeDiagram(app.currParentID)
		.then((existingResult:any) => {
		
			const originalNodes = existingResult.data.model.layers[1].models;
			console.log(originalNodes);
			var ogIDs = Array();
			for (const key in originalNodes){
				ogIDs.push(key);
			}
			console.log(ogIDs);
			 var newNodes = new Array();
			nodes.forEach(element => {
				console.log(element);
				if(ogIDs.indexOf(element.getOptions().id)!= -1)
				{
					console.log("found og node");
				}
				else {
					newNodes.push(element);
				}
			});
			newNodes.forEach(element => {

				element.setPosition(element.getX(), element.getY() + 30);
			});
			app.diagramEngine.repaintCanvas();

		});


		

		// var compareLayer = layers[layers.length-1];
		// console.log(compareLayer.getModels());
		// var models = compareLayer.getNodes();

		
		// for (const key in models)
		// 	{
		// 	   // Get the strongly typed value with this name:
		// 	   const model = models[key];
		// 	   const nodes = model.getNodes();
		// 	   let node = nodes[Object.keys(nodes)[0]];
		// 	   node.setPosition(node.getX() + 30, node.getY() + 30);
			   



		// 	}
	}

	return (
		<div className={styles.canvasWrapper}>
			<style>{".show-filter{border:2px solid #a4cb48;border-radius:5px}"}</style>
			<NodeViewSidebar parentList={parentList} compareProduct={getCompareDiagram} adjustPosition={adjustPosition} changeProduct={getDiagram} />

			<CanvasWidget className={styles.canvasWidget} engine={app.diagramEngine} />
		</div>
	);
}