import React, { useEffect, useState } from 'react';
import styles from './ItemForm.module.scss';
import { FormProvider, useForm } from "react-hook-form";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FormGroup, FormGroupData } from '../FormGroup/FormGroup';
import { FormField, FormFieldData } from '../FormField/FormField';
import '../../styles/tabs.scss';
import { useAuth } from '../../services/AuthContext';
import { useAppContext } from '../../services/AppContext';

type MyProps = {
  onSuccess: Function,
  onFailure: Function,
  id: string
};

export function ItemForm (props: MyProps) {
  const [tabData, setTabData] = useState<TabData>([]);
  const appState = useAppContext();
  const methods = useForm();
  const auth = useAuth();

  const onSubmit = (event: any) => {
    console.log(event);

    auth.saveItemForm(props.id, event).then((response: any) => {
      props.onSuccess(response.data.id);

      let node:any = appState.diagramEngine.getModel().getNode(response.data.id);
      if (typeof node !== 'undefined') {
        node.getOptions().name = response.data.name;
      }

      appState.hideModal();
    });
  }

  const closeModal = () => {
    appState.hideModal();
  }

  useEffect(() => {
    setTabData([]);
    auth.getItemForm(props.id).then((response: { status: number; data: React.SetStateAction<TabData>; }) => {
      if (response.status === 200) {
        setTabData(response.data);

        methods.reset();
      }
    }).catch(function(e:any) {

    })
  }, [props])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Tabs>
          <TabList>
          {
            (() => {
              let tabs:JSX.Element[] = [];
              for(let tab of tabData) {
                tabs.push(<Tab key={tab.name}>{tab.name}</Tab>);
              }


             

              return tabs
            })()
          }
          
          </TabList>
          {
            (() => {
              let tabPanels:JSX.Element[] = [];
              for(let tab of tabData) {
                tabPanels.push(
                  <TabPanel key={tab.name} forceRender={true}>
                    {
                      (() => {
                        let fields:JSX.Element[] = [];
                        for(let tabForm of tab.form) {
                          if (tabForm.type === 'field') {
                            fields.push(<FormField {...tabForm} key={tabForm.id} />);
                          }
                          else if (tabForm.type === 'group') {
                            fields.push(<FormGroup {...tabForm} key={tabForm.id} />);
                          }
                        }
                        return fields;
                      })()
                    }
                  </TabPanel>
                );
              }
              return tabPanels;
            })()
          }
        </Tabs>
        <div className={[styles.cancel].join(' ')} onClick={closeModal}>Cancel</div>
        <input className={[styles.submit].join(' ')} type="submit" value="Save" />
      </form>
    </FormProvider>
  );
}

export type TabData = Array<{
  name: string;
  form: Array<FormFieldData|FormGroupData>
}>;